<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="Unconfined Package Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Setup" size="medium" />
            <a
              :href="require('../../assets/UN/setup-unconfined-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/setup-unconfined-min.jpg" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The Unconfined Package Test is performed to determine if there are
              hazardous effects outside the package arising from accidental
              ignition or initiation of the contents.
            </p>
            <TitleComponent class="py-8" title="Application" size="medium" />
            <p class="p-light">
              Limited to only twelve UN numbered HD 1.4S articles:
            </p>
            <ul class="list-disc p-light pl-8 mt-4">
              <li>Charges, shaped (UN0441)</li>
              <li>Cartridges, power device (UN0323)</li>
              <li>Detonators, non-electric (UN0455)</li>
              <li>Detonators, electric (UN0456)</li>
              <li>Charges, explosive, commercial (UN0445)</li>
              <li>Charges, bursting, plastics bonded (UN0460)</li>
              <li>Detonators for ammunition (UN0366)</li>
              <li>Detonator assemblies, non-electric (UN0500)</li>
              <li>Articles, explosive, n.o.s. (UN0349)</li>
              <li>Components, explosive train, n.o.s. (UN0384)</li>
              <li>Fuzes, detonating (UN0367)</li>
              <li>Substances, explosive, n.o.s. (UN0481)</li>
            </ul>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Ignition stimulus (device function)</td>
                    <td>
                      Ensure article is functioned as intended or designed (or
                      equivalent)
                    </td>
                    <td>
                      <small
                        >Most likely incident to be encountered during
                        transport</small
                      >
                    </td>
                    <td>
                      Initiation by the articles own means of initiation. If
                      there isn’t a means of initiation on the article,
                      instructions for initiation are given in Test 6 (d) in the
                      UN Manual of Tests and Criteria.
                    </td>
                  </tr>
                  <tr>
                    <td>Stimulus placement</td>
                    <td>Ignition/ initiation in the center</td>
                    <td>
                      <small>Assess effect upon surrounding materials</small>
                    </td>
                    <td>Stimulus placed in the center of the package</td>
                  </tr>
                  <tr>
                    <td>Witness plate</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>3 mm thick mild steel</td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Sufficient trials to enable unambiguous interpretation of
                      the results
                    </td>
                    <td></td>
                    <td>At least 3 trials</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Denting or perforation of the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>Damage to witness plate</td>
                  </tr>
                  <tr>
                    <td>Presence of flash or flame</td>
                    <td>Video camera evidence</td>
                    <td>
                      Flash or flame capable of igniting the specified sheet of
                      paper
                    </td>
                  </tr>
                  <tr>
                    <td>Projection of the explosive contents</td>
                    <td>
                      Visual post-test inspection of packaging and immediate
                      area
                    </td>
                    <td>Explosive contents exterior of disrupted packaging</td>
                  </tr>
                  <tr>
                    <td>Fragment passes completely through the packaging</td>
                    <td>Visual post-test inspection of packaging</td>
                    <td>
                      Hole through packaging wall; a projection or fragment
                      retained/ stuck in packaging wall is considered
                      non-hazardous
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *If there are hazardous effects outside the package, then the
                product is excluded from Compatibility Group S.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Projection Passing Completely Through Package (+)"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/go-unconfined-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/go-unconfined-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of No Evidence of a Hazardous Effect Outside the Package (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88936052?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88936052"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="Example Video of No Evidence of a Hazardous Effect Outside the Package (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88936053?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88936053"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
